var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("product")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.pricing.product))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav3")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("RM " + _vm._s(_vm.pricing.price))])]), _vm.pricing.product == 'Wasiat' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("description")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.pricing.description))])]) : _vm._e()]), _vm.organizationCode == 'ISYNERGY' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v("SKU ID")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.pricing.isynergy_sku_id))])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("offer-sku")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.pricing.isynergy_offer_sku_id))])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.awaris-share")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("RM " + _vm._s(_vm.pricing.awaris_share))])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("business-partner")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("RM " + _vm._s(_vm.pricing.organization_share))])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.possb-share")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("RM " + _vm._s(_vm.pricing.prooffice_share))])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("other-sharing")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("RM " + _vm._s(_vm.pricing.others_share))])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("other-sharing")) + " (" + _vm._s(_vm.$t("description")) + ")")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.pricing.others_description))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment-price")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("RM " + _vm._s(_vm.pricing.amendment_price))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-awaris-amendments")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" RM " + _vm._s(_vm.pricing.amendment_awaris_share) + " ")])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_vm.organizationType == 'Awaris' ? _c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.partner-share")))]) : _vm._e(), _vm.organizationType != 'Awaris' ? _c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-business-partner-amendments")))]) : _vm._e(), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" RM " + _vm._s(_vm.pricing.amendment_organization_share) + " ")])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-possb-amendments")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" RM " + _vm._s(_vm.pricing.amendment_prooffice_share) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.pricing.status == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.pricing.status != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left"
  }), _vm._v(_vm._s(_vm.$t("back")) + " ")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'organization.pricing.edit',
        params: {
          organization_id: _vm.organizationId,
          pricing_id: _vm.pricingId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("update")))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }